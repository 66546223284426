import React, { useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { navigate } from 'gatsby';
import { auth } from '../../utils/fb';

export default function Logout() {
  useEffect(() => {
    signOut(auth)
      .then(() => navigate('/auth/login'))
      .catch((err) => console.log(err.message));
  });
  return <></>;
}
